.container {
    padding: 2rem 1rem;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 480px) {
    .wrapper {
        justify-content: center;
    }
}