.container {
    height: 100vh;
    height: 100dvh;
    position: relative;
}

#leftContainer {
    padding: 2rem;
    width: 50%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

#leftContainer div {
    margin: auto 0;
}

#leftContainer #contactInfo {
    display: block;
}

#leftContainer #contactInfo li {
    margin-bottom: 1rem;
    height: fit-content;
}

#leftContainer #contactInfo li span {
    display: block;
    width: 100%;
    border-radius: .5rem;
    padding: .1rem .5rem;
    background-color: var(--color-2l-50);
}

#leftContainer #contactInfo li span a {
    color: inherit;
    text-decoration: none;
}
#leftContainer #contactInfo li span a:hover {
    text-decoration: underline;
}

#mapsContainer {
    width: 100%;
    height: 100%;
}

#mapsContainer a {
    display: block;
    height: calc(100% - 1.3rem);
}

.maps {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 480px) {
    #leftContainer {
        width: 100%;
    }

    #leftContainer #contactInfo li {
        overflow-x: hidden;
    }
}