#pageTitle {
    height: 35vh;
    height: 35dvh;
    color: var(--color-5l-50);
    background-color: var(--color-3l-100);
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

@media only screen and (max-width: 480px) {
    #pageTitle {
        align-items: center;
        padding: 4rem 1rem;
    }
}