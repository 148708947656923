.wrapper {
    padding: .5rem;
    background-color: var(--color-3l-100);
    border: 3px solid var(--color-4l-100);
    border-radius: .5rem;
    cursor: default;
}

.wrapper.transparent {
    background-color: var(--color-3l-50);
}

.wrapper.transparent:hover {
    background-color: var(--color-3l-100);
}

.wrapper.mix:nth-child(odd) {
    position: relative;
    z-index: 1;
    border: 3px solid var(--color-2l-100);
    box-shadow: 6px 6px 0 var(--color-1l-100);
    transform: translate(-3px, -3px);
    transition: .3s;
}

.wrapper.mix:nth-child(odd):hover {
    box-shadow: none;
    transform: translate(0, 0);
    transition: .3s;
}

.wrapper.mix:nth-child(even) {
    transition: .3s;
}

.wrapper.mix:nth-child(even):hover {
    position: relative;
    z-index: 1;
    box-shadow: 6px 6px 0 var(--color-5l-100);
    transform: translate(-3px, -3px);
    transition: .3s;
}

.full {
    width: 100%;
    margin: 0 .5%;
}

.half {
    width: 49%;
    margin: 0 .5%;
}

.third {
    width: 32.33%;
    margin: 0 .5%;
}

.forth {
    width: 24%;
    margin: 0 .5%;
}

.center {
    text-align: center;
}

.multiple {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {

    .third,
    .forth {
        width: 19rem;
    }
}