@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200;400;600;800');

:root {
  /* Color scheme in light mode */
  --color-0l-100: rgba(255, 255, 255, 1);
  --color-1l-100: rgba(22, 38, 47, 1);
  --color-1l-50: rgba(22, 38, 47, 0.5);
  --color-2l-100: rgba(141, 191, 201, 1);
  --color-2l-50: rgba(141, 191, 201, 0.5);
  --color-3l-100: rgba(251, 249, 217, 1);
  --color-3l-50: rgba(251, 249, 217, 0.5);
  --color-4l-100: rgba(228, 187, 97, 1);
  --color-4l-50: rgba(228, 187, 97, 0.5);
  --color-5l-100: rgba(166, 0, 0, 1);
  --color-5l-50: rgba(166, 0, 0, 0.5);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  font-family: 'JetBrains Mono', monospace;
  font-size: 16px;
  color: rgba(22, 38, 47, 1);
  text-rendering: optimizeSpeed;
}

#root {
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;
}

h1,
h2,
h3 {
  text-align: center;
}

h1,
h2 {
  margin: 15px 0;
}

h3 {
  margin: 10px 0;
}

li {
  list-style: none;
}

input,
textarea,
select {
  padding: 3px;
  background-color: var(--color-0l-100);
  border: 3px solid var(--color-2l-100);
  font-family: 'JetBrains Mono', monospace;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: 3px solid var(--color-5l-100);
}

.hidden {
  display: none !important;
}

::-moz-selection {
  background-color: var(--color-2l-50);
}

::selection {
  background-color: var(--color-2l-50);
}

::-webkit-scrollbar {
  display: none;
}

section {
  min-height: 80vh;
  width: 100%;
}

.container {
  padding: 4%;
}

.half-container {
  width: 48%;
}