.container {
    height: 100vh;
    height: 100dvh;
    display: flex;
    background-color: var(--color-3l-50);
}

form {
    margin: auto;
    width: 40%;
}

form div {
    margin: .5rem 0;
    width: 100%;
}

form div label span {
    vertical-align: super;
    color: var(--color-5l-100);
}

form div label:first-child {
    display: inline-block;
    height: 100%;
    width: 40%;
    vertical-align: middle;
}

form div input:not(input[type="radio"]),
form div select {
    display: inline-block;
    height: 100%;
    width: 60%;
    border-radius: .5rem;
}

form div input+label {
    margin-left: 2%;
    margin-right: 5%;
}

form div input[type="radio"] {
    accent-color: var(--color-2l-100);
}

form div button {
    margin: 0 auto;
    margin-top: 2rem;
    padding: 5px;
    display: block;
    height: 2rem;
    width: 50%;
    border: 3px solid var(--color-2l-100);
    border-radius: .5rem;
    font-family: inherit;
    color: #000;
    background-color: inherit;
    cursor: pointer;
}

form div button[disabled] {
    color: #c0c9d7;
    cursor: default;
}

form div div label img {
    vertical-align: bottom;
    margin-right: 5%;
}

@media only screen and (max-width: 480px) {
    form {
        width: 90%;
    }

    form div input+label {
        margin-left: 1%;
        margin-right: 4%;
    }
}