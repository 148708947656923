@import url('https://fonts.googleapis.com/css2?family=Nabla&display=swap');

#heroBanner {
    min-height: 0;
    height: 75vh;
    position: relative;
}

#heroBanner #heroContainer {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#heroBanner #heroContainer #heroVideo {
    transform: translateY(-15%);
    width: 100%;
    height: 140%;
    object-fit: cover;
}

#heroBanner #heroText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#heroBanner #heroText h1 {
    font-family: 'Nabla', cursive;
    font-size: 2.5rem;
    text-shadow: #000 1px 0 10px;
}

#heroBanner #heroText h3 span {
    font-size: 1.3rem;
    font-style: italic;
    text-decoration: underline var(--color-5l-100) wavy;
}

#shortIntroSec {
    background-color: var(--color-3l-50);
}

#shortIntroSec #introContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

#shortIntroSec #introContainer #leftContainer {
    width: 50%;
}

#shortIntroSec #introContainer #leftContainer #introDetail {
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#shortIntroSec #introContainer #leftContainer #statisticNumber {
    width: 80%;
    display: flex;
    margin: 0 auto;
    margin-top: -1.5rem;
}

#shortIntroSec #introContainer #leftContainer #statisticNumber div {
    height: 8rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#shortIntroSec #introContainer #leftContainer #statisticNumber div + div {
    margin-left: 1%;
}

#shortIntroSec #introContainer #leftContainer #statisticNumber div h4 {
    margin: auto;
}

#shortIntroSec #introContainer #leftContainer #statisticNumber .counterNumber {
    font-size: 3rem;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--color-5l-100);
    background-color: var(--color-3l-100);
    border: none;
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
}

#shortIntroSec #introContainer #rightContainer {
    width: 50%;
    padding: 5%;
}

#shortIntroSec #introContainer #rightContainer #introImg {
    width: 100%;
    border: 3px solid var(--color-2l-100);
    border-radius: .5rem;
    transform: rotate(-6deg) scale(.9);
    transition: .5s;
}
#shortIntroSec #introContainer #rightContainer #introImg:hover {
    transform: rotate(0) scale(1);
    transition: .5s;
}

#featureMemberSec {
    background-color: var(--color-3l-100);
}

#featureEventSec {
    background-color: var(--color-3l-50);
}

#registerInvitationSec {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
#registerInvitationSec::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/Footer/Footer.jpg") no-repeat center;
    background-size: cover;
    -webkit-filter: blur(.3rem);
    filter: blur(.3rem);
    z-index: -1;
}

#registerInvitationSec #invitation {
    height: 8rem;
    position: relative;
}

#registerInvitationSec #invitation h3 {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
    #heroBanner {
        height: 60vh;
    }

    #heroBanner #heroText {
        display: none;
    }

    #shortIntroSec #introContainer {
        flex-direction: column-reverse;
    }

    #shortIntroSec #introContainer #leftContainer {
        width: 100%;
    }

    #shortIntroSec #introContainer #leftContainer #introDetail {
        height: 15rem;
    }

    #shortIntroSec #introContainer #leftContainer #statisticNumber {
        width: 90%;
    }

    #shortIntroSec #introContainer #leftContainer #statisticNumber div {
        height: 10rem;
    }

    #shortIntroSec #introContainer #rightContainer {
        width: 100%;
    }

    #registerInvitationSec #invitation {
        height: 10rem;
    }
}