.button {
    padding: 0 .5rem;
    max-height: 100%;
    height: 2.5rem;
    background-color: var(--color-3l-50);
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: inherit;
    border-radius: .5rem;
    cursor: pointer;
}

.button+.button {
    margin-left: .3rem;
}

.button:nth-child(odd) {
    border: 3px solid var(--color-2l-100);
}

.button:nth-child(even) {
    border: 3px solid var(--color-4l-100);
}

.disabled {
    visibility: hidden;
}